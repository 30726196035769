export const getSpotifyToken = async (): Promise<string | null> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';
  
  try {
    const response = await fetch(`${API_BASE_URL}/GetSpotifyToken`);
    
    if (!response.ok) {
      throw new Error(`Error fetching token: ${response.statusText}`);
    }
    const data = await response.json();
    return data.token;
  } catch (error) {
    console.error('Failed to fetch Spotify token', error);
    return null;
  }
};