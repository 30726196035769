import React from "react";

import { Col, Row } from "react-bootstrap";
import FolioItem, { EditGigFolioItem, NewGigFolioItem } from "../live/folio-item";
import { useIsAuthenticated } from '@azure/msal-react';
import { IGig } from "../../services/interfaces";

function Live() {
    const isAuthenticated = useIsAuthenticated();

    return (

        <section className="live" id="live">
            {/* <video className='full-bk-video' playsInline autoPlay muted loop poster="../images/live-bg.JPG" src="./img/AmazonsClips.mp4" id="bgvid" typeof="video/mp4" /> */}


            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>UPCOMING GIGS</h5>
                </Col>
            </Row>
            <Row className="portfolio-content">
                <Col className="col-twelve">
                    <div className="block-1-3 block-mob-full stack" id="folio-wrapper" style={{ position: 'relative', height: '340.094px' }}>
                        {isAuthenticated ? (
                            <>
                                {tempGigs.map((gig, index) => (
                                    
                                    <EditGigFolioItem key={index} gig={gig} />
                                ))}
                                <NewGigFolioItem />
                            </>
                        ) : (
                                tempGigs.map((gig, index) => (
                                    gig.visible && new Date() > gig.visibleDate && new Date() < gig.expireDate &&
                                <FolioItem key={index} gig={gig} />
                            ))
                        )}
                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Live;

export const tempGigs: IGig[] = [
    {
        id: 1,
        location: "Leeds",
        venue: "Brudenell Social Club",
        //months are from 0!
        date: new Date(2024, 7, 11),
        imageUrl: "./img/live/Silver_Moth_Poster.jpg",
        videoPosterUrl: null,
        altTxt: "11thAug_SilverMoth_Poster",
        link: "https://mailchi.mp/brudenellsocialclub/silver-moth-live-in-leeds",
        visible: true,
        //months are from 0!
        visibleDate: new Date(2024, 1, 14),
        //months are from 0!
        expireDate: new Date(2024, 9, 4)
    },
    {
        id: 2,
        location: "Leeds",
        venue: "Oporto",
        date: new Date(2024, 2, 14),
        imageUrl: "./img/live/cielposter3.webp",
        videoPosterUrl: null,
        altTxt: "CielOportoPoster",
        link: "https://futuresound.seetickets.com/event/ciel/oporto/2798747?offercode=support&direct=true",
        visible: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2024, 2, 14)
    },
    {
        id: 2,
        location: "Leeds",
        venue: "Oporto",
        date: new Date(2024, 2, 14),
        imageUrl: "./img/live/cielposter3.webp",
        videoPosterUrl: null,
        altTxt: "CielOportoPoster",
        link: "https://futuresound.seetickets.com/event/ciel/oporto/2798747?offercode=support&direct=true",
        visible: false,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2024, 2, 14)
    }];