import { Routes, Route, useLocation } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import Login from './components/login';
import Home from './pages/home';
import ReactGA from "react-ga4";


import 'bootstrap/dist/css/bootstrap.min.css';

import './css/base.css';
import './css/main.css';
import './css/vendor.css';

import './App.css';
import OneSheet from './pages/oneSheet';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useEffect } from 'react';
import SwimPr from './pages/swim-pr';


function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
}

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {

  usePageTracking();

  return (
    <MsalProvider instance={pca}>
      <ParallaxProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pr" element={<OneSheet />} />
          <Route path="/swim-pr" element={<SwimPr />} />
          <Route path="/swim-pr/:fullVid" element={<SwimPr />} />
        </Routes>
      </ParallaxProvider>
    </MsalProvider>
  );
};

export default App;