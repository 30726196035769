import React from "react";

import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";

function SupportedBy() {

    return (

        <section className="live" id="live">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>SUPPORTED BY</h5>
                </Col>
            </Row>
            <Row className="portfolio-content">
                <Col className="col-three">
                    <img src="./img/associates/YouthMusic.png" alt="Youth Music" style={{objectFit:"contain", maxWidth:"100%"}} />
                </Col>
                <Col className="col-three">
                    <img src="./img/associates/helpmusicians.png" alt="Help Musicians" style={{ mixBlendMode: "lighten", maxWidth:"100%" }} />
                </Col>
                <Col className="col-three">
                    <img src="./img/associates/bbcintro.png" alt="BBC Intro" style={{ mixBlendMode: "lighten", maxWidth:"100%" }} />
                </Col>
                <Col className="col-three">
                    <img src="./img/associates/launchpad.png" alt="BBC Intro" style={{ filter: "invert(100%)", mixBlendMode: "lighten", maxWidth:"100%" }} />
                </Col>
            </Row>
        </section>
    );
}

export default SupportedBy;