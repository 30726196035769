import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../services/authConfig';

const Login: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  };

  return (
    <div>
      <button onClick={handleLogin}>Sign In / Sign Up</button>
    </div>
  );
};

export default Login;