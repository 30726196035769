import { Col, Row } from "react-bootstrap";

function PrSummaryBox() {

    return (

        <section className="live-alt" id="live-alt">
            <Row className="section-intro">
                <Col className="col-twelve highlight-box">
                    <Row className="section-intro" style={{ backgroundColor: "#002f6d" }}>
                        <Col className="col-twelve">
                            <h5 style={{color:"#538ad4"}}>Featured On</h5>
                        </Col>
                    </Row>
                    <Row className="portfolio-content">
                        <Col className="col-eight" style={{ flex: "none" }}>
                            <div className="block-1-3 block-mob-full stack" id="folio-wrapper" style={{ position: 'relative' }}>
                                <h4 style={{color:"#538ad4"}}>APPLE MUSIC / AMAZON MUSIC</h4>
                                <p style={{color:"white"}}>New in Alternative, New in Indie, Notebooks & Chill
                                </p>
                                <br />
                                <h4 style={{color:"#538ad4"}}>RADIO</h4>
                                <p style={{color:"white", textAlign:"justify"}} >
                                    BBC Radio 1's Alyx Wycombe's Introducing Rock Show, BBC Radio 6, BBC Radio Wales A-List, 
                                    BBC Music Introducing with Emily Pilbeam & Live in Session, 
                                    'Ones to Watch', BBC Music Introducing 'One's to Watch', 
                                    BBC Radio Wales (Adam Walton, Wynne Evans, Carol Voughdamen, Janice Long, Jason Mohammad),
                                     Amazing Radio (Frankie Francis, Shell Zenner, Charlie Ashcroft), Amazing Radio USA, XS Manchester (Jim Salveson) 
                                     Live in Session & “Best of',  BBC Radio Leeds Interviews and more.

                                </p>
                                <br/>
                                <br/>

                                <blockquote>
                                    <p style={{textAlign:"justify"}}>“Bit of a poppy, Indie number, a little bit lighter for the show, a bit different, it's a TUNE,
                                        and I found it on the uploader so jumped right on it”</p>
                                    <a href="https://www.bbc.co.uk/programmes/m001kts3" target="_blank" rel="noopener noreferrer">
                                        <cite>Alyx Holcombe BBC Radio 1</cite>
                                    </a>
                                </blockquote>

                                <blockquote>
                                    <p style={{textAlign:"justify"}}>"I think this is their strongest track to date you know"</p>
                                    <a href="https://www.bbc.co.uk/programmes/p0bj8w54" target="_blank" rel="noopener noreferrer">
                                        <cite>Emily Pilbeam BBC Introducing, Manic (Live Sessions)</cite>
                                    </a>
                                </blockquote>

                                <blockquote>
                                    <p style={{textAlign:"justify"}}>“Such a slick sound on this, if you like Pale Waves, you'll like this! These would be perfect to see at a festival”</p>
                                    {/* <a href=""> */}
                                    <cite>Emily Pilbeam BBC Introducing</cite>
                                    {/* </a> */}
                                </blockquote>

                            </div>
                        </Col>
                        <Col className="col-four">

                            <img src="https://is1-ssl.mzstatic.com/image/thumb/Video114/v4/a4/68/85/a468851d-f7df-fc32-90d5-c9708d18056f/Joba52760cd-7136-4cd9-9594-c344a90c8064-110895690-PreviewImage_preview_image_nonvideo_sdr-Time1617810178139.png/305x305cc.webp"
                                alt="playlist"
                                width="250px"
                                style={{ paddingBottom: "1em" }} />
                            <img src="https://is1-ssl.mzstatic.com/image/thumb/Video114/v4/bf/78/af/bf78af29-2c21-5954-1607-c08e91ed82d1/Jobde0405dc-0d47-43b8-aae7-0dacc5519991-110098423-PreviewImage_preview_image_nonvideo_sdr-Time1615975926020.png/305x305cc.webp"
                                alt="playlist"
                                width="250px"
                                style={{ paddingBottom: "1em" }} />
                            <img src="./img/onesheet/AvaBBCR1Play.jpg"
                                alt="bbcR1Image"
                                width={"250px"}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section >
    );
}

export default PrSummaryBox;