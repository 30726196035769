import React from 'react';
import { Modal, Button } from 'react-bootstrap';
// import { IGig } from '../../services/interfaces';

type AddGigModalProps = {
    show: boolean;
    handleClose: () => void;
};

const AddGigModal: React.FC<AddGigModalProps> = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Gig</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Add your form fields here */}
                <p>Form fields for adding a new gig go here.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary">
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddGigModal;