import { Col, Row } from "react-bootstrap";


function Press() {

    return (

        <section className="live" id="live">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>PRESS</h5>
                </Col>
            </Row>
            <Row>
                <Col className="col-nine" style={{ flex: "none" }}>

                    <blockquote>
                        <p style={{ textAlign: "justify" }}>“But make no mistake, for all it’s pop sensibility this is a rock song; it’s heavy, It’s just not done with those standard guitar sounds.”</p>
                        <a href="http://localsoundfocus.com/single-review-ava-in-the-dark-manic/" target="_blank" rel="noopener noreferrer">
                            <cite>Local Sound Focus</cite>
                        </a>
                    </blockquote>
                    <br/>

                    <blockquote>
                        <p style={{ textAlign: "justify" }}>“Ava in the Dark will set a new industry standard if they continue in this vein.”</p>
                        <a href="https://lefuturewave.com/2022/01/07/introducing-ava-in-the-dark-manic/" target="_blank" rel="noopener noreferrer">
                            <cite>
                                lefuturewave
                            </cite>
                        </a>
                    </blockquote>
                    <br/>
                    <blockquote>
                        <p style={{textAlign:"justify"}}>“One word: WOW! If your blood doesn’t boil here, fire your doctor, change your meds and come back. Don’t delete this band. Save them right now, like them, love them, embrace them, share them with your friends. Listen to Ava In The Dark on repeat in your bedroom, in your car, in your favorite pub, just everywhere, and you’ll smile all day long.”</p>
                        <a href="https://turnupthevolume.blog/2020/10/06/save-them-immediately-flabbergasting-pop-with-ava-in-the-dark-and-new-single-delete-us-forever/" target="_blank" rel="noopener noreferrer">
                            <cite>
                                Turn up the volume
                            </cite>
                        </a>
                    </blockquote>

                </Col>
                <Col className="col-three">
                    <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {[
                            { href: "https://www.analoguetrash.com/blog/ava-in-the-dark-manic", text: "Analog Trash" },
                            { href: "https://www.chalkpitrecords.com/new-music/ava-in-the-dark-manic", text: "Chalkpit Records" },
                            { href: "https://lastdaydeaf.com/fresh-n-green-january-16th/", text: "Last Day Deaf" },
                            { href: "https://www.launchpad-music.com/supported-artists/ava-in-the-dark", text: "Launchpad" },
                            { href: "https://istoodupandisaidyeah.music.blog/2020/09/25/track-review-ava-in-the-dark-delete-us-forever/", text: "I Said Yeah" },
                            { href: "https://moggblog.com/2020/09/28/moggblogs-monday-magic-22/", text: "MoggBlogg" },
                            { href: "http://localsoundfocus.com/single-review-ava-in-the-dark-delete-us-forever/", text: "Local Sound Focus" },
                            { href: "https://www.chalkpitrecords.com/new-blog/09/2/track-of-the-day-ava-in-the-dark-have-their-sights-set-on-getting-out-of-the-dark-and-onto-the-big-bright-stage", text: "Chalkpit" },
                            { href: "https://www.analoguetrash.com/blog/ava-in-the-dark-delete-us-forever", text: "Analogue Trash" },
                            { href: "https://popmuzik.se/108259/ava-in-the-dark-delete-us-forever/", text: "Popmuzik" },
                            { href: "https://spotlightuk.co.uk/articles/2020/10/4/single-review-delete-us-forever-by-ava-in-the-dark", text: "Spotlight" },
                            { href: "https://glassefactory.com/tunesday-top-30-8-september-2020/", text: "Glasse Factory Playlist" },
                            { href: "https://whenthehornblows.com/content/2020/10/9/weekly-top-tracks-98", text: "When The Horn Blows Weekly Top Tracks #98" },
                            { href: "https://www.indiegroovemusicblog.co.uk/reviews/ava-in-the-dark-delete-us-forever", text: "Indie Groove Music Blog" },
                            { href: "https://comeherefloyd.com/ava-in-the-dark-delete-us-forever/", text: "Come Here Floyd" },
                            { href: "http://www.chordblossom.com/blogs/no-fixed-abode-5", text: "Chord Blossom" }
                        ].map((item, index) => (
                            <li key={index} style={{ marginBottom: '0.1em' }}>
                                <a href={item.href} target="_blank" rel="noopener noreferrer">
                                    <cite>- {item.text}</cite>
                                </a>
                            </li>
                        ))}
                    </ul>

                </Col>
            </Row >
        </section >
    );
}

export default Press;