import React from "react";

import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";

function LiveVideo() {

    return (

        <section className="live" id="live">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>LIVE VIDEO</h5>
                </Col>
            </Row>
            <Row className="portfolio-content">
                <Col className="col-twelve">
                    <div className="block-1-3 block-mob-full stack" id="folio-wrapper" style={{ position: 'relative' }}>
                        <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                            <ReactPlayer
                                url={"https://youtu.be/KR1aYAAf5H0"}
                                playing={false}
                                controls={true}
                                loop={true}
                                muted={false}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 2
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <br />
            <Row className="portfolio-content">
                <Col className="col-twelve">
                    <h4>
                        LIVE REVIEWS
                    </h4>
                    <blockquote>
                        <p style={{textAlign:"justify"}}>"Ethereal tones, a tight rhythm section and a lone, leading guitar were well forged together to make their signature sound such an astonishing appetiser. "</p>
                        <a href="https://recordweekly.com/2022/10/17/the-amazons-leeds-beckett-su/" target="_blank" rel="noopener noreferrer">
                            <cite>
                                {"IGoulding, Record Weekly (Live Review - The Amazons)"}
                            </cite>
                        </a>
                    </blockquote>


                    <blockquote>
                        <p style={{textAlign:"justify"}}>"A sonically vibrant, up-and-coming Leeds-based alt-rock pop band offered an ominous yet slick, melodic performance which after a few songs gave us a sound I would describe as similar to the likes of Pale Waves or Nothing But Thieves with a combination of melodic vocals and catchy synth/guitar lines that definitely succeeded in getting people warmed up for the main event."</p>
                        <a href="https://www.thegryphon.co.uk/2022/10/21/the-amazons-deliver-electrifying-set-at-leeds-beckett-students-union/" target="_blank" rel="noopener noreferrer">
                            <cite>
                                {"George Bolger, The Gryphon (Live Review - The Amazons)"}
                            </cite>
                        </a>
                    </blockquote>
                </Col>
            </Row>
        </section>
    );
}

export default LiveVideo;