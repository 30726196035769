import { Configuration, LogLevel } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authority: process.env.REACT_APP_AUTHORITY || '',
    knownAuthorities: [process.env.REACT_APP_AUTHORITY_DOMAIN || ''],
    redirectUri: process.env.REACT_APP_REDIRECT_URI || '',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", 
    storeAuthStateInCookie: false, 
  },
  system: {
    loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case LogLevel.Error:
                    // console.error(message);
                    return;
                case LogLevel.Info:
                    // console.info(message);
                    return;
                case LogLevel.Verbose:
                    // console.debug(message);
                    return;
                case LogLevel.Warning:
                    // console.warn(message);
                    return;
                default:
                    return;
            }
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true,
    },
  },
};


export const loginRequest = {
  scopes: process.env.REACT_APP_SCOPES?.split(',') || [],
};