// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from './services/authConfig';
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ReactGA from 'react-ga4';

import App from './App';

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();


const GA_TRACKING_ID = process.env.GA_TRACKING_ID || "G-FVFGKS8HBX";
ReactGA.initialize(GA_TRACKING_ID);

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App pca={msalInstance} />
      </BrowserRouter>
    </React.StrictMode>
  );

reportWebVitals();