import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddGigModal from './add-gig-modal';
import { useState } from "react";
import { IGig } from '../../services/interfaces';
import EditGigModal from "./edit-gig-modal";
import ReactPlayer from 'react-player';
// import { Link, Navigate } from "react-router-dom";

type Props = { gig: IGig };

export default function FolioItem({ gig }: Props) {
    return (
        <div className="bgrid folio-item">
            <div className="item-wrap">
                {/* If the image is a youtube link or mp4, use the vid as poster */}
                {gig.videoPosterUrl ?
                    (<>
                        <ReactPlayer
                            url={gig.videoPosterUrl}
                            alt={gig.altTxt}
                            fallback={<img src={gig.imageUrl} alt={gig.altTxt} />}
                            playing={true}
                            controls={false}
                            loop={true}
                            muted={true}
                        />
                        <h2 className="folio-video-title">{gig.venue}</h2>
                        <span className="folio-video-types">{gig.location + " " + gig.date.toLocaleDateString()}</span>
                    </>
                    )
                    :
                    (
                        <img src={gig.imageUrl} alt={gig.altTxt} style={{ width: '100%', height: 'auto' }} />
                    )}

                <div className="overlay" onClick={() => {
                    window.open(gig.link, '_blank', 'noopener,noreferrer');
                }}>
                    <div className="folio-item-table">
                        <div className="folio-item-cell">
                            <h3 className="folio-title">{gig.venue}</h3>
                            <span className="folio-types">{gig.location + " " + gig.date.toLocaleDateString()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export function EditGigFolioItem({ gig }: Props) {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="bgrid folio-item">
            <div className="item-wrap edit-gig-button" style={{ alignContent: "center" }}>
                <img src={gig.imageUrl} alt={gig.altTxt} />

                <div className="overlay" onClick={handleOpenModal}>
                    <div className="folio-item-table">
                        <div className="folio-item-cell add-gig-item-cell">
                            <h3 className="folio-title">{gig.venue}</h3>
                            <span className="folio-types">{gig.location + " " + gig.date.toString()}</span>
                        </div>
                    </div>
                </div>
                <FontAwesomeIcon icon={faPenToSquare} size="xl" color="white" className="edit-icon" style={{ height: "2em" }} />
            </div>
            <EditGigModal show={showModal} handleClose={handleCloseModal} gig={gig} />
        </div>
    )
};

export function NewGigFolioItem() {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="bgrid folio-item">
            <div className="item-wrap add-gig-button" style={{ alignContent: "center" }}>
                <img src={"./img/grey-square.png"} alt={"button to add a new gig"} />
                <FontAwesomeIcon icon={faPlus} size="2xl" color="black" className="center-icon" style={{ height: "2em" }} />
                <div className="overlay" onClick={handleOpenModal}>
                    <div className="folio-item-table">
                        <div className="folio-item-cell add-gig-item-cell">
                            <h3 className="folio-title"> {""} </h3>
                            <span className="folio-types add-gig-text">{"Add Gig"}</span>
                        </div>
                    </div>
                </div>
            </div>
            <AddGigModal show={showModal} handleClose={handleCloseModal} />
        </div>
    )
};