import { Col, Row } from "react-bootstrap";

function Bio() {

    return (

        <section className="live" id="live">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>BIO</h5>
                </Col>
            </Row>
            <Row className="portfolio-content">
                <Col className="col-twelve">
                    <div className="block-1-3 block-mob-full stack" id="folio-wrapper" style={{ position: 'relative', textAlign:"justify" }}>
                        Ava in the Dark is an alternative rock and dreamgaze band from Leeds, UK, led by Welsh vocalist and producer Kiera Bickerstaff and Tommie James, alongside bassist Izzy Hobbs and drummer Emma Liu.
                        The band blends dream-like atmospheres and shoegaze-inspired textures. Known for heavy guitar riffs, haunting melodies, and dynamic shifts between aggression and serenity, they create a unique mix of raw emotion and sonic experimentation.<br/><br/>

                        Kiera and Tommie initially experimented with synth-pop before discovering their true sound with "Manic," which won the Marshall Amplification Best Original Track Award (Group) from Youth Music. <br/><br/>

                        Influenced by artists like Wolf Alice, Nothing But Thieves, Phantogram, and Softcult, their latest track "SWIM" showcases a rockier yet atmospheric sound, recorded at Marshall Amplification Studios in Milton Keynes.<br/><br/>
                        Ava in the Dark supported The Amazons on the Leeds date of their UK tour and went on a full UK tour with Spyres. They received support from BBC Radio 1’s Alyx Wycombe, BBC Introducing's Emily Pilbeam, and were on the BBC Radio Wales A-list,
                        and have showcased at festivals such as Live at Leeds, FOCUS Wales, Hanwell Hootie, and more. <br/><br/>

                        Recent highlights include a co-headline show at Hyde Park Book Club, a gig at Brudenell Social Club with Silvermoth (featuring Mogwai frontman Stuart Braithwaite), and performances alongside Lucia & The Best Boys, Saint Agnes, and Ciel.<br/><br/>
                        With a promising 2024 ahead, the band is set to release new music and continue captivating audiences across the UK with their distinct blend of sonic intensity and emotion.<br/><br/>

                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Bio;